import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    CreateReportPayload,
    GetReconcilationReportsParams,
    GetReconcilationReportsResponse,
} from './types/reconcilationReports'

const BASE_URL = process.env.VUE_APP_GATEWAY_URL

type ApiGetReconcilationReportsResponse = Promise<AxiosResponse<GetReconcilationReportsResponse>>
type ApiCreateReportResponse = Promise<AxiosResponse<void>>

export function getList(params: GetReconcilationReportsParams): ApiGetReconcilationReportsResponse {
    return http.get(`${BASE_URL}/cabinet/api/reconciliation-report`, { params })
}

export function createReport(payload: CreateReportPayload): ApiCreateReportResponse {
    return http.post(`${BASE_URL}/cabinet/api/reconciliation-report`, payload)
}

export default {
    getList,
    createReport,
}
