
import { defineComponent, onBeforeMount } from 'vue'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'
import SPagination from '@/common/components/SPagination/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SCalendar from '@/common/components/SCalendar/index.vue'
import SelectedDateRange from '@/modules/HistoryNew/components/SelectedDateRange/index.vue'

// Module Components
import ReconcilationReportsList from '@/modules/ReconcilationReportsModule/components/ReconcilationReportsList/index.vue'

// Composable
import useReconcilationReports from '@/modules/ReconcilationReportsModule/composable/useReconcilationReports'
import { useTitle } from 'vue-composable'

// icons
import iSearchWhite from '@/assets/icons/SearchWhite.svg'

export default defineComponent({
    components: {
        SButton,
        SModal,
        SCalendar,
        SInput,
        ReconcilationReportsList,
        SPagination,
        'icon-search-white': iSearchWhite,
        SelectedDateRange,
    },
    setup() {
        useTitle('Акт сверки')

        const {
            state,
            filters_state,
            getList,
            onDateFromChange,
            onCreateReport,
            resetFilters,
            stopCreate,
            createReport,
        } = useReconcilationReports()

        onBeforeMount(async () => {
            await getList(1)
        })

        return {
            state,
            filters_state,
            //
            onDateFromChange,
            onCreateReport,
            resetFilters,
            createReport,
            getList,
            stopCreate,
        }
    },
})
