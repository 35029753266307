export const useFile = () => {
    const saveFile = (content: File | Blob, file_name: string) => {
        const download_url = window.URL.createObjectURL(content)

        const link = document.createElement('a')
        link.href = download_url
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const saveFileByUrl = (download_url: string, file_name: string) => {
        const link = document.createElement('a')
        link.href = download_url
        link.target = '_blank'
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return { saveFile, saveFileByUrl }
}
