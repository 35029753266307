import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "SInput__icon"
}
const _hoisted_2 = { class: "SInput__holder" }
const _hoisted_3 = {
  key: 0,
  class: "SInput__calendar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_calendar = _resolveComponent("s-calendar")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock("div", {
    class: ["SInput", { 'with-icon': _ctx.$slots.icon, invalid: _ctx.isInvalid, disabled: _ctx.disabled }],
    ref: "componentref"
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("input", {
        type: "text",
        class: ["default-input", { 'with-icon': _ctx.$slots.icon }],
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        value: _ctx.getInputValue,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitInput && _ctx.emitInput(...args))),
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openDate && _ctx.openDate(...args))),
        maxlength: _ctx.maxlength
      }, null, 42, ["disabled", "placeholder", "value", "maxlength"]), [
        [_directive_maska, _ctx.maska]
      ]),
      (_ctx.isDateOpened)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_s_calendar, {
              class: "primary",
              disabled: _ctx.disabled,
              "disable-less-date": false,
              date: _ctx.getDateValue,
              "onUpdate:date": [
                _cache[4] || (_cache[4] = ($event: any) => (_ctx.getDateValue = $event)),
                _ctx.emitDateInput
              ],
              "can-deselect": "",
              onDeselect: _ctx.emitDateInput
            }, null, 8, ["disabled", "date", "onUpdate:date", "onDeselect"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}