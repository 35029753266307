
import { defineComponent, onMounted, PropType } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

// Helpers
import { cutName } from '@/common/helpers'
import { ReconcilationReport } from '@/new-services/types/reconcilationReports'

// icons
import iEyeGreen from '@/assets/icons/EyeGreen.svg'
import useReconcilationReports from '../../composable/useReconcilationReports'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-eye': iEyeGreen,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        reports: {
            type: Array as PropType<ReconcilationReport[]>,
            default: () => [],
        },
    },
    emits: ['refresh', 'mounted'],
    setup(_, { emit }) {
        const { showReport } = useReconcilationReports()

        const emitRefresh = () => {
            emit('refresh')
        }

        onMounted(() => {
            emit('mounted')
        })

        return { emitRefresh, cutName, showReport }
    },
})
