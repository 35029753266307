import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "SPagination" }
const _hoisted_2 = { class: "SPagination__page active" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_vector_left = _resolveComponent("icon-vector-left")!
  const _component_icon_vector_right = _resolveComponent("icon-vector-right")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.totalPages > 5)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ["SPagination__control SPagination__control--left", { disabled: _ctx.value <= 1 }],
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePage(_ctx.value - 1)))
        }, [
          _createVNode(_component_icon_vector_left)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.totalPages <= 5)
      ? (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.totalPages, (page) => {
          return (_openBlock(), _createBlock("div", {
            class: ["SPagination__page", { active: _ctx.value === page }],
            key: `pagination-page-${page}`,
            onClick: ($event: any) => (_ctx.changePage(page))
          }, _toDisplayString(page), 11, ["onClick"]))
        }), 128))
      : (_ctx.value < 3)
        ? (_openBlock(), _createBlock(_Fragment, { key: 2 }, [
            _createVNode("div", {
              class: ["SPagination__page", { active: _ctx.value === 1 }],
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePage(1)))
            }, " 1 ", 2),
            _createVNode("div", {
              class: ["SPagination__page", { active: _ctx.value === 2 }],
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePage(2)))
            }, " 2 ", 2),
            _createVNode("div", {
              class: ["SPagination__page", { active: _ctx.value === 3 }],
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changePage(3)))
            }, " 3 ", 2)
          ], 64))
        : (_ctx.value > _ctx.totalPages - 2)
          ? (_openBlock(), _createBlock(_Fragment, { key: 3 }, [
              _createVNode("div", {
                class: ["SPagination__page", { active: _ctx.value === _ctx.totalPages - 2 }],
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changePage(_ctx.totalPages - 2)))
              }, _toDisplayString(_ctx.totalPages - 2), 3),
              _createVNode("div", {
                class: ["SPagination__page", { active: _ctx.value === _ctx.totalPages - 1 }],
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changePage(_ctx.totalPages - 1)))
              }, _toDisplayString(_ctx.totalPages - 1), 3),
              _createVNode("div", {
                class: ["SPagination__page", { active: _ctx.value === _ctx.totalPages }],
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changePage(_ctx.totalPages)))
              }, _toDisplayString(_ctx.totalPages), 3)
            ], 64))
          : (_openBlock(), _createBlock(_Fragment, { key: 4 }, [
              _createVNode("div", {
                class: "SPagination__page",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changePage(_ctx.value - 1)))
              }, _toDisplayString(_ctx.value - 1), 1),
              _createVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
              _createVNode("div", {
                class: "SPagination__page",
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changePage(_ctx.value + 1)))
              }, _toDisplayString(_ctx.value + 1), 1)
            ], 64)),
    (_ctx.totalPages > 5)
      ? (_openBlock(), _createBlock("div", {
          key: 5,
          class: ["SPagination__control SPagination__control--right", { disabled: _ctx.value >= _ctx.totalPages }],
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.changePage(_ctx.value + 1)))
        }, [
          _createVNode(_component_icon_vector_right)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}