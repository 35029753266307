import { notify } from '@kyvg/vue3-notification'

interface NotificationOptionsI {
    group: string
    type: string
    title: string
    text?: string
}

const DEFAULT_DURATION = 2500

export default function () {
    function success(options: NotificationOptionsI) {
        notify({
            // @ts-ignore
            duration: DEFAULT_DURATION,
            group: options.group,
            type: options.type,
            title: options.title,
            text: options.text,
        })
    }

    function alert(options: NotificationOptionsI) {
        notify({
            // @ts-ignore
            duration: DEFAULT_DURATION,
            group: options.group,
            type: options.type,
            title: options.title,
            text: options.text,
        })
    }

    function failure(options: NotificationOptionsI, error?: unknown) {
        if (Array.isArray(error)) {
            error.forEach((iterated_error: string) => {
                notify({ title: options.title, group: options.group, text: iterated_error, type: 'danger' })
            })

            return
        }

        notify({
            title: options.title,
            // @ts-ignore
            duration: DEFAULT_DURATION,
            group: options.group,
            // @ts-ignore
            text: error.message,
            type: 'danger',
        })
    }

    return { success, alert, failure }
}
