
import { defineComponent } from 'vue'

import iVectorLeft from '@/assets/icons/VectorLeft.svg'
import iVectorRight from '@/assets/icons/VectorRight.svg'

export default defineComponent({
    components: {
        'icon-vector-left': iVectorLeft,
        'icon-vector-right': iVectorRight,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const changePage = (page: number) => {
            if (page <= 0) return
            if (page > props.totalPages) return
            if (page === props.value) return

            emit('update:value', page)
            emit('change', page)
        }

        return { changePage }
    },
})
