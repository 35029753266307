import { onBeforeUnmount, onMounted, Ref } from 'vue'

type Element = Ref<HTMLElement | null>

export default function (element: Element, callback: () => any) {
    if (!element) return

    const listener = (event: MouseEvent) => {
        if (
            event.target !== element.value &&
            event.composedPath().includes(element.value as HTMLElement)
        ) {
            return
        }

        callback()
    }

    const addListeners = () => {
        window.addEventListener('click', listener)
    }

    const removeListeners = () => {
        window.removeEventListener('click', listener)
    }

    onMounted(addListeners)
    onBeforeUnmount(removeListeners)

    return { listener }
}
