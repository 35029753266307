
import { computed, defineComponent, reactive, Ref, ref } from 'vue'

import { maska } from 'maska'

// components
import SCalendar from '@/common/components/SCalendar/index.vue'
import useClickOutside from '@/common/composable/useClickOutside'

export default defineComponent({
    components: { SCalendar },
    directives: {
        maska,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maska: {
            type: Object,
            default: null,
        },
        maxlength: {
            type: String,
            default: null,
        },
        isDate: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const state = reactive({
            is_date_opened: false,
        })

        const openDate = () => {
            state.is_date_opened = true
        }

        const closeDate = () => {
            state.is_date_opened = false
        }

        const componentref = ref(null) as Ref<HTMLElement | null>
        useClickOutside(componentref, closeDate)

        const formatDateString = (dateString: string) => {
            const dateArray = dateString.split('-')
            return dateArray.reverse().join('.')
        }
        const dateToString = (date: Date) => date.toISOString().split('T')[0]

        const emitInput = (event: Event) => {
            if (props.isDate) {
                return
            }

            const target = event.target as HTMLInputElement
            emit('update:value', target.value)
        }

        const emitChange = (event: Event) => {
            if (props.isDate) {
                return
            }

            const target = event.target as HTMLInputElement
            emit('change', target.value)
        }

        const emitDateInput = (date: Date) => {
            const value = date ? dateToString(date) : null
            emit('update:value', value)
        }

        const getDateValue = computed((): Date | null => {
            if (props.value) {
                return new Date(props.value)
            } else {
                return null
            }
        })
        const getInputValue = computed(() => {
            const date = getDateValue.value

            if (props.isDate && date) {
                return formatDateString(dateToString(date))
            } else {
                return props.value
            }
        })
        const isDateOpened = computed(() => props.isDate && state.is_date_opened)

        return {
            componentref,
            //
            emitInput,
            emitChange,
            emitDateInput,
            openDate,
            //
            isDateOpened,
            getDateValue,
            getInputValue,
        }
    },
})
