import { reactive } from 'vue'

import reconcilationReports from '@/new-services/reconcilationReports.service'

// types
import {
    CreateReportPayload,
    ReconcilationReport,
    ReconcilationReportFile,
} from '@/new-services/types/reconcilationReports'

// composable
import useNotifications from '@/common/composable/useNotifications'
import { useFile } from '@/common/composable/useFile'
import { showFileByUrl } from '@/common/helpers'

export default function () {
    const notifications = useNotifications()
    const { saveFileByUrl } = useFile()

    const state = reactive({
        is_loading: false,
        is_failed: false,
        is_create: false,
        is_create_loading: false,
        is_file_loading: false,
        //
        pagination: {
            current_page: 1,
            total_pages: 1,
        },
        //
        list: [] as ReconcilationReport[],
        //
        date_range: {
            date_from: null as Date | null,
            date_to: null as Date | null,
        },
    })

    const filters_state = reactive({
        dateFrom: null as string | null,
        dateTo: null as string | null,
    })

    //

    const dateToString = (date: Date) => date.toISOString().split('T')[0]

    const getList = async (page: number) => {
        state.pagination.current_page = page

        try {
            state.is_loading = true

            const params = {
                page: state.pagination.current_page,
                limit: 20,
                ...filters_state,
            }

            const response = await reconcilationReports.getList(params)

            state.list = response.data.data
            state.pagination.total_pages = response.data.meta.last_page

            state.is_failed = false
        } catch (error) {
            console.error(error)
            state.is_failed = true
        } finally {
            state.is_loading = false
        }
    }

    const onDateFromChange = (date: string | null) => {
        filters_state.dateFrom = date
    }

    const onCreateReport = () => {
        state.is_create = true
    }

    const resetFilters = () => {
        filters_state.dateFrom = null
        filters_state.dateTo = null

        getList(1)
    }

    const stopCreate = () => {
        state.is_create = false

        state.date_range.date_from = null
        state.date_range.date_to = null
    }

    const createReport = async () => {
        if (!state.date_range.date_from || !state.date_range.date_to) return

        try {
            state.is_create_loading = true

            const payload = {
                dateFrom: dateToString(state.date_range.date_from),
                dateTo: dateToString(state.date_range.date_to),
            }

            await reconcilationReports.createReport(payload as CreateReportPayload)

            notifications.success({
                title: 'Акт сверки создан!',
                group: 'create-module',
                type: 'success',
            })
            stopCreate()
            getList(1)
        } catch (error) {
            console.error(error)
            notifications.failure(
                {
                    group: 'reports-module',
                    type: 'error',
                    title: 'Ошибка создания акта',
                },
                error
            )
        } finally {
            state.is_create_loading = false
        }
    }

    const showReport = async (file: ReconcilationReportFile) => {
        const { path } = file

        try {
            state.is_file_loading = true

            showFileByUrl(path)
        } catch (error) {
            notifications.failure(
                {
                    group: 'reports-module',
                    type: 'error',
                    title: 'При отображении возникла ошибка',
                },
                error
            )
        } finally {
            state.is_file_loading = false
        }
    }

    const downloadReport = async (file: ReconcilationReportFile) => {
        const { path, original_name } = file

        try {
            state.is_file_loading = true

            saveFileByUrl(path, original_name)
        } catch (error) {
            notifications.failure(
                {
                    group: 'reports-module',
                    type: 'error',
                    title: 'При скачивании возникла ошибка',
                },
                error
            )
        } finally {
            state.is_file_loading = false
        }
    }

    return {
        state,
        filters_state,
        //
        onDateFromChange,
        onCreateReport,
        resetFilters,
        stopCreate,
        createReport,
        showReport,
        downloadReport,
        getList,
    }
}
